/* @import url('https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css'); */
/* @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css'; */
/* @import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css'; */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ag-row {
  border-bottom: none !important;
}
.ag-theme-material {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(249, 245, 227);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);
  --ag-alpine-accent-color: #e85936 !important;
  --ag-alpine-primary-color: #e85936 !important;
  --ag-checkbox-checked-color: #e85936 !important;
  --ag-row-hover-color: #e3e8fe94 !important;
  --ag-font-size: 17px;
  --ag-font-family: 'Inter' !important;
  --ag-alpine-active-color: #e85936 !important;
  --ag-material-primary-color: #e85936 !important;
}
.ag-sort-indicator-icon.ag-sort-descending-icon,
.ag-sort-indicator-icon.ag-sort-ascending-icon {
  color: #e85936;
}
.ag-sort-indicator-icon.ag-sort-order {
  color: #1a0a06;
}
.ag-header-icon {
  cursor: pointer;
}
/* .ag-cell-wrapper {
  margin-top: 20px !important;
} */
.ag-selection-checkbox {
  margin-top: -5px !important;
}
.cell-wrap-text {
  white-space: normal !important;
}
.ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: #e85936 !important;
}
.ag-theme-material .ag-side-button-button:hover {
  color: #e85936 !important;
}
.ag-header-cell-label span.ag-icon.ag-icon-filter {
  color: #e85936;
}
.ag-side-bar .ag-side-buttons {
  display: none;
}

.ag-center-cols-container {
  margin-right: 0px !important;
}
.ag-pinned-right-cols-container {
  margin-right: 0px !important;
}

.hover-effect:hover {
  transform: translateY(-4px);
  transition-duration: 500ms;
}

.carousel .carousel-indicators button {
  background-color: #e85936;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.bg-image {
  position: relative;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 5px;
}

.popover.bs-popover-end .popover-arrow:after {
  border-right-color: #0c224a;
}

.disabled {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.25);
}

.disabled:hover {
  cursor: not-allowed;
}

.strike {
  text-decoration: Line-Through;
}

.custom-input:focus {
  border-color: #e85936 !important; /* Apply custom border color */
  box-shadow: 0 0 0.5px 0.5px rgba(232, 89, 54, 0.5) !important; /* Adjust shadow for more visibility */
  outline: none !important; /* Ensures outline is removed */
}

.custom-input {
  border-color: #cccccc !important; /* Apply custom border color */
  box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.25) !important; /* Adjust shadow for more visibility */
  outline: none !important; /* Ensures outline is removed */
}

.text-justify {
  text-align: justify !important;
}

#editableTitle {
  position: relative;
}

#editableTitle[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%;
  left: 100%; /* Move the tooltip to the right */
  transform: translateY(-50%); /* Vertically center the tooltip */
  margin-left: 10px; /* Optional: Adds space between the text and the tooltip */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3px;
  border-radius: 2px;
  white-space: nowrap;
  font-size: 12px;
}

details > summary {
  list-style: none; /* Removes the arrow */
  cursor: pointer; /* Optional: Customize the cursor */
}

/* Optional: Additional styles for the summary */
details > summary::marker {
  content: ''; /* Removes the marker (for better compatibility) */
}
