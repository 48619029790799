//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

.card-dashed {
  width: 383.5px;
}

.wl-odd-row {
  background: linear-gradient(rgba(0, 59, 207, 0.25), var(--kt-body-bg));
}

.wl-even-row {
  background: linear-gradient(rgba(248, 178, 155, 0.45), var(--kt-body-bg));
}

.card-hover:hover {
  border: 2px $card-border-style var(--kt-primary);
}

.row-hover:hover {
  border: 2px $card-border-style var(--kt-primary);
  border-radius: $card-border-radius;
}

.gradientHissa {
  background: linear-gradient(144.87deg, var(--kt-primary) -45.4%, var(--kt-primary-light) 99.13%);
}

.header-bg {
  background-color: var(--kt-header-fixed-bg-color);
}

.nav-line-tabs .nav-item .nav-link {
  color: var(--kt-headings-color);
}

.nav-line-tabs .nav-item .nav-link:hover {
  color: var(--kt-primary);
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom: 2px solid var(--kt-primary);
}

.nav-line-tabs .nav-item .nav-link .nav-arrow {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: $menu-arrow-space;
  width: $menu-arrow-size;
  height: $menu-arrow-size;

  &:after {
    display: block;
    width: 100%;
    content: ' ';
    will-change: transform;
    background-size: 100% 100%;
    @include svg-bg-icon(arrow-top, var(--kt-text-muted));
    /*rtl:begin:remove*/
    @include svg-bg-icon(arrow-bottom, var(--kt-text-muted));
    /*rtl:end:remove*/
  }
}

.no-border-radius {
  border-radius: 0 !important;
}

.floating-button {
  position: fixed;
  display: flex;
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: $scrolltop-z-index;
  justify-content: center;
  align-items: center;
  bottom: $scrolltop-bottom;
  right: $scrolltop-end;
  background-color: var(--kt-scrolltop-bg-color);
  box-shadow: var(--kt-scrolltop-box-shadow);
  opacity: $scrolltop-opacity;
  transition: $transition-link;
  @include border-radius($border-radius);

  .svg-icon {
    @include svg-icon-size(24px);
    color: var(--kt-scrolltop-icon-color);
  }

  > i {
    font-size: 1.3rem;
    color: var(--kt-scrolltop-icon-color);
  }

  &:hover {
    background-color: var(--kt-scrolltop-bg-color-hover);

    .svg-icon,
    i {
      color: var(--kt-scrolltop-icon-color-hover);
    }
  }
  opacity: var(--kt-scrolltop-opacity-on);
  animation: animation-scrolltop 0.4s ease-out 1;
  display: flex;

  &:hover {
    transition: $transition-link;
    opacity: var(--kt-scrolltop-opacity-hover);
  }
}
