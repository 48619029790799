//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
// base font-size
$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-weight-normal: 500 !default;
$font-weight-light: 400 !default;
$font-weight-lighter: 300 !default;
$font-weight-extra-light: lighter !default;

// Heading sizes
$h1-font-size: $font-size-base * 1.45 !default; // 18.85px
$h2-font-size: $font-size-base * 1.32 !default; // 17.16px
$h3-font-size: $font-size-base * 1.26 !default; // 16.38x
$h4-font-size: $font-size-base * 1.2 !default; // 15.6px
$h5-font-size: $font-size-base * 1.1 !default; // 14.3px
$h6-font-size: $font-size-base * 1 !default; // 13.0px

// Theme colors
// Primary
$white: #ffffff !default;
$black: #000000 !default;
$primary: #e85936 !default; // Bootstrap variable
$gray-heading: #464e5f !default; // Bootstrap variable
$gray-light: #f1f3f6 !default; // Bootstrap variable
$blue-heading: #3699ff !default;
$brown-heading: #4c3d3a !default;
$card-gray: #f3f6f9 !default;
$border-radius-xl: 0.5rem !default;
$primary-active: #e75836;
$primary-light: #e8593666;
$primary-light-dark: #212e48;
$primary-inverse: #ffffff;
$popover: #150a6b;
$initial: #007fa3;
$growth-card: #0c224a;
$termsheet-bg: #eef6ff;
$ticker: #f2f2f2;
$icon-bg: #d9d9d9;
$secondary-txt: #0c224a;
$primary-fill: #f4e8e7;
$secondary-fill: #e7e9ed;

// Success
$success: #50cd89;
$success-badge: #dcfceb;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-badge: #f8dfe5;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;

//grey
$gray-100: #f6f9fb !default;
$gray-200: #eff2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;

//grey dark
$gray-100-dark: #1b1b29 !default;
$gray-200-dark: #2b2b40 !default;
$gray-300-dark: #323248 !default;
$gray-400-dark: #474761 !default;
$gray-500-dark: #565674 !default;
$gray-600-dark: #6d6d80 !default;
$gray-700-dark: #92929f !default;
$gray-800-dark: #cdcdde !default;
$gray-900-dark: #ffffff !default;

// Light
$light: $gray-100 !default;
$light-dark: $gray-200-dark !default;
$light-active: $gray-200 !default;
$light-active-dark: $gray-300-dark !default;
$light-light: gba($gray-100, 0.75) !default;
$light-inverse: $gray-600 !default;
$light-inverse-dark: $gray-600-dark !default;

//secondary
$secondary: #f5f8fa;
$secondary-dark: #ef5b2d33;
$secondary-active: #ef5b2d33;
$secondary-active-dark: #ef5b2d66;
$secondary-light: #ef5b2d99;
$secondary-light-dark: #ef5b2d22;
$secondary-inverse: #ef5b2ddd;
$secondary-inverse-dark: #ef5b2d99;

$card-box-shadow: none !default;
$card-border-dashed-color: $primary !default;
$card-border-radius: $border-radius-xl !default;
$card-py: 1.5rem !default;
$card-px: 1.5rem !default;
$headings-font-weight: 500 !default;

$donut-colors: (
  0: #9b71f3,
  1: #73c3a4,
  2: #797b94,
  3: #83b5ff,
  4: #86567a,
  5: #82d8d5,
  6: #ffd5bc,
  7: #ffd78e,
  8: #ffb876,
  9: #ffd5bc,
  10: #86567a,
  11: #fc9fbc,
  12: #797b94,
  13: #e08283,
  14: #c8d046,
  15: #32c5d2,
  16: #8877a9,
  17: #c5b96b,
  18: #4b77be,
  19: #bfbfbf,
  20: #e87e04,
) !default;

// dark
$dark: $gray-900 !default;
$dark-dark: $gray-900-dark !default;
$dark-active: darken($gray-900, 3%) !default;
$dark-active-dark: lighten($gray-900-dark, 3%) !default;
$dark-light: $gray-200 !default;
$dark-light-dark: $gray-200-dark !default;
$dark-inverse: $white !default;
$dark-inverse-dark: $gray-100-dark !default;

$text-muted: $gray-400 !default;
$text-muted-dark: $gray-400-dark !default;

$theme-colors: (
  'white': $white,
  // custom color type
  'light': $light,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'success-badge': $success-badge,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'danger-badge': $danger-badge,
  'dark': $dark,
  'gray-heading': $gray-heading,
  'gray-light': $gray-light,
  'primary-light': $primary-light,
  'growth-card': $growth-card,
  'termsheet-bg': $termsheet-bg,
  'ticker': $ticker,
  'icon-bg': $icon-bg,
  'secondary-txt': $secondary-txt,
  'primary-fill': $primary-fill,
  'secondary-fill': $secondary-fill,
  'card-gray': $card-gray,
  'blue-heading': $blue-heading,
) !default;

$theme-colors-dark: (
  'dark': $dark-dark,
  'light': $light-dark,
  'secondary': $secondary-dark,
  'card-gray': $light-dark,
) !default;

$theme-text-colors: (
  'white': $white,
  'primary': $primary,
  'secondary': $secondary,
  'light': $light,
  'success': $success,
  'success-badge': $success-badge,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'danger-badge': $danger-badge,
  'dark': $dark,
  'muted': $text-muted,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'popover': $popover,
  'blue-heading': $blue-heading,
  'success-active': $success-active,
  'initial': $initial,
  'growth-card': $growth-card,
  'termsheet-bg': $termsheet-bg,
  'ticker': $ticker,
  'icon-bg': $icon-bg,
  'secondary-txt': $secondary-txt,
  'primary-fill': $primary-fill,
  'secondary-fill': $secondary-fill,
  'gray-heading': $gray-heading,
  'gray-light': $gray-light,
) !default;

$card-border-radius: $border-radius-xl !default;

$gray-900: #464e5f;
$headings-color: $gray-900 !default;
$headings-color-dark: $gray-800-dark !default;
$body-color-dark: $gray-800-dark !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2.2rem,
  8: 1.8rem,
) !default;
$display-font-weight: $font-weight-normal !default;

$component-active-color-dark: $primary-light-dark !default;

// Font Sizes
$font-sizes: (
  1: $h1-font-size,
  // 22.75px
  2: $h2-font-size,
  // 19.50px
  3: $h3-font-size,
  // 17.55px
  4: $h4-font-size,
  // 16.25px
  5: $h5-font-size,
  // 14.95px
  6: $h6-font-size,

  // 13.95px
  7: $font-size-base * 0.95,
  // 12.35px
  8: $font-size-base * 0.85,
  // 11.05px
  9: $font-size-base * 0.75,
  // 9.75px
  10: $font-size-base * 0.5,

  // 6.50px
  base: $font-size-base,
  // 13px
  fluid: 100%,

  // 100%
  2xs: $font-size-base * 1.85,
  //24px
  2x: $font-size-base * 2,
  // 26px
  2qx: $font-size-base * 2.25,
  // 29.25px
  2hx: $font-size-base * 2.5,
  // 32.5px
  2tx: $font-size-base * 2.75,

  // 35.75px
  3x: $font-size-base * 3,
  // 39px
  3qx: $font-size-base * 3.25,
  // 42.25px
  3hx: $font-size-base * 3.5,
  // 45.5px
  3tx: $font-size-base * 3.75,

  // 48.75px
  4x: $font-size-base * 4,
  // 52px
  4qx: $font-size-base * 4.25,
  // 55.25px
  4hx: $font-size-base * 4.5,
  // 58.5px
  4tx: $font-size-base * 4.75,

  // 61.75px
  5x: $font-size-base * 5,
  // 65px
  5qx: $font-size-base * 5.25,
  // 68.25px
  5hx: $font-size-base * 5.5,
  // 71.5px
  5tx: $font-size-base * 5.75 // 74.75px,,,,,,,,,,,,,,,
) !default;

$custom-sizes: (
  unset: unset,
  25: 25%,
  30: 30%,
  50: 50%,
  70: 70%,
  75: 75%,
  85: 85%,
  100: 100%,
  auto: auto,
  1px: 1px,
  2px: 2px,
  3px: 3px,
  4px: 4px,
  5px: 5px,
  6px: 6px,
  7px: 7px,
  8px: 8px,
  9px: 9px,
  10px: 10px,
  15px: 15px,
  20px: 20px,
  25px: 25px,
  30px: 30px,
  35px: 35px,
  38px: 38px,
  40px: 40px,
  45px: 45px,
  50px: 50px,
  55px: 55px,
  60px: 60px,
  65px: 65px,
  70px: 70px,
  75px: 75px,
  80px: 80px,
  85px: 85px,
  90px: 90px,
  95px: 95px,
  100px: 100px,
  125px: 125px,
  150px: 150px,
  175px: 175px,
  185px: 185px,
  190px: 190px,
  195px: 195px,
  200px: 200px,
  210px: 210px,
  225px: 225px,
  250px: 250px,
  275px: 275px,
  300px: 300px,
  325px: 325px,
  350px: 350px,
  375px: 375px,
  400px: 400px,
  425px: 425px,
  450px: 450px,
  475px: 475px,
  500px: 500px,
  525px: 525px,
  550px: 550px,
  575px: 575px,
  600px: 600px,
  625px: 625px,
  630px: 630px,
  650px: 650px,
  700px: 700px,
  750px: 750px,
  800px: 800px,
  850px: 850px,
  900px: 900px,
  950px: 950px,
  1000px: 1000px,
) !default;

// Define custom colors
$equity-bg: rgba(36, 29, 186, 0.05);
$equity-text: rgba(36, 29, 186, 0.7);
$preference-bg: rgba(45, 169, 0, 0.05);
$preference-text: rgba(45, 169, 0, 0.7);
$ccd-bg: rgba(0, 163, 199, 0.05);
$ccd-text: rgba(0, 163, 199, 1);
$ocd-bg: rgba(231, 146, 18, 0.05);
$ocd-text: rgba(231, 146, 18, 1);
$note-bg: rgba(248, 97, 97, 0.05);
$note-text: rgba(248, 97, 97, 1);
$warrant-bg: rgba(105, 4, 220, 0.2);
$warrant-text: rgba(105, 4, 220, 1);
$rps-bg: rgba(186, 168, 237, 0.05);
$rps-text: rgba(213, 203, 244, 1);
$ncd-bg: rgba(166, 169, 0, 0.05);
$ncd-text: rgba(166, 169, 0, 0.6);
$ocps-bg: rgba(0, 173, 111, 0.05);
$ocps-text: rgba(0, 173, 111, 0.6);
$ocrps-bg: rgba(231, 146, 18, 0.05);
$ocrps-text: rgba(231, 146, 18, 0.6);
$debenture-bg: rgba(255, 212, 103, 0.05);
$debenture-text: rgba(255, 212, 103, 1);
$options-bg: rgba(248, 97, 97, 0.05);
$options-text: rgba(248, 97, 97, 0.6);
$ccps-bg: rgba(45, 169, 0, 0.05);
$ccps-text: rgba(45, 169, 0, 1);
$forfeited-bg: rgba(6, 128, 119, 0.05);
$forfeited-text: rgba(6, 128, 119, 1);

// Create Bootstrap utility classes
.custom-security {
  &.equity {
    background-color: $equity-bg;
    color: $equity-text;
  }
  &.preference {
    background-color: $preference-bg;
    color: $preference-text;
  }
  &.ccd {
    background-color: $ccd-bg;
    color: $ccd-text;
  }
  &.ocd {
    background-color: $ocd-bg;
    color: $ocd-text;
  }
  &.note {
    background-color: $note-bg;
    color: $note-text;
  }
  &.warrant {
    background-color: $warrant-bg;
    color: $warrant-text;
  }
  &.rps {
    background-color: $rps-bg;
    color: $rps-text;
  }
  &.ncd {
    background-color: $ncd-bg;
    color: $ncd-text;
  }
  &.ocps {
    background-color: $ocps-bg;
    color: $ocps-text;
  }
  &.ocrps {
    background-color: $ocrps-bg;
    color: $ocrps-text;
  }
  &.debenture {
    background-color: $debenture-bg;
    color: $debenture-text;
  }
  &.options {
    background-color: $options-bg;
    color: $options-text;
  }
  &.ccps {
    background-color: $ccps-bg;
    color: $ccps-text;
  }
  &.forfeited {
    background-color: $forfeited-bg;
    color: $forfeited-text;
  }
}
